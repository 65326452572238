<template>
	<div
		class="full-height pa-10-20"
	>
		<h6>{{ program.name }}</h6>

		<Search
			class="mt-10 box"
			:search="search"
			:option="search_option"
			:codes="codes"
			:user="user"

			@reset="reset"
			@click="getSearch"
			@agency="setAgency"
		>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.nice"
				@change="getSearch(1)"
			>
				<option value="">나이스 인증</option>
				<option
					v-for="add in codes.nice_confirm"
					:key="'nice_confirm_' + add.code"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.confirm"
				@change="getSearch(1)"
			>
				<option value="">심사 상태</option>
				<option
					v-for="add in codes.shop_confirm"
					:key="'shop_confirm_' + add.code"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.state"
				@change="getSearch(1)"
			>
				<option value="">계정 상태</option>
				<option
					v-for="add in codes.shop_state"
					:key="'shop_state_' + add.code"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
		</Search>

		<div class="mt-10 pa-10 bg-white flex-column overflow-y-auto">

			<div class="justify-space-between">
				<div>총 <span class="font-weight-bold">{{ search.total_count }}</span> 건</div>

				<div class="text-right">
					<button
						class="box mr-10 pa-4-10 size-px-12"
						@click="toExcel"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
					<select
						class="pa-5-10 mr-10 size-px-12"
						v-model="search.size"
						@change="getSearch(1)"
					>
						<option
							v-for="cnt in codes.list_cnt"
							:key="'cnt_' + cnt"
							:value="cnt"
						>{{ cnt }} 건씩 보기</option>
					</select>
				</div>
			</div>

			<table
				v-if="items.length > 0"
				class="mt-10 table table-even top-line-identify"
			>
				<colgroup>
					<col width="50px" />
					<col width="80px" />
					<col width="auto" />
					<col width="120px" />
					<col width="auto" />

					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="130px" />
					<col width="auto" />

					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="100px" />
				</colgroup>
				<thead>
				<tr>
					<th>NO</th>
					<th>PG사</th>
					<th>결제방식</th>
					<th>아이디</th>
					<th>가맹점명</th>

					<th>사업자구분</th>
					<th>사업자번호</th>
					<th>대표자명</th>
					<th>수수료율</th>
					<th>정산주기</th>

					<th>나이스 인증</th>
					<th>심사</th>
					<th>등록일</th>
					<th>상태</th>
					<th>상세</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'list_' + index"
				>
					<td>{{ (10 * (search.page ? search.page - 1 : '0')) + index + 1 }}</td>
					<td>{{  item.pgVanName ? item.pgVanName : '-'  }}</td>
					<td>{{  item.verify_payment_name ? item.verify_payment_name : '-'  }}</td>
					<td>{{  item.id  }}</td>
					<td class="text-left">{{  item.shopName }}</td>


					<td>{{  item.business_name }}</td>
					<td>{{  item.businessNumber }}</td>
					<td>{{  item.representativeName }}</td>
                    <td>
                        <ul>
                            <li class="flex justify-space-between">
                                <span>배달비</span>
                                <span>{{ item.quickFee ? item.quickFee : '-'  }}%</span>
                            </li>
                            <li class="flex justify-space-between">
                                <span>월세</span>
                                <span>{{ item.monthlyFee ? item.monthlyFee : '-'  }}%</span>
                            </li>
                            <li class="flex justify-space-between">
                                <span>D-Day</span>
                                <span>{{ item.pgFee ? item.pgFee : '-'  }}%</span>
                            </li>
                        </ul>
                    </td>
					<td>{{  item.settlementCycle > 0 ? 'D+' + item.settlementCycle : '즉시정산' }}</td>

					<td :class="'color-' + item.nice_color">{{  item.nice_name }}</td>
					<td :class="'color-' + item.confirm_color">{{ item.confirm_name }}</td>
					<td>{{ item.regDate }}</td>
					<td :class="'color-' + item.state_color">{{ item.state_name }}</td>
					<td>
						<button
							class="bg-identify pa-5-10 size-px-12"
							@click="toDetail(item)"
						>상세보기</button>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center mt-10 top-line-identify"
			>
				<div class="text-center pa-20">
					<v-icon
						class="size-px-48 color-icon"
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>

		</div>

        <Pagination
            :program="program"
            :align="'center'"
            :options="search"

            class="mt-auto"
            @click="getSearch"
        ></Pagination>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"

			@finish="is_excel = !is_excel"
		></Excel>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Excel from "@/components/Excel";
export default {
	name: 'MerchantList',
	components: {Excel, Search, Pagination},
	data: function(){
		return {
            user: [],
			program: {
				name: '가맹점 목록'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				page: this.$route.query.page ? this.$route.query.page : 1
				,total_count: 0
				,size: this.$route.query.size ? this.$route.query.size : 10
				,search_type: this.$route.query.search_type ? this.$route.query.search_type : ''
				,keyword: this.$route.query.keyword ? this.$route.query.keyword : ''
				,state: this.$route.query.state ? this.$route.query.state : ''
				,nice: this.$route.query.nice ? this.$route.query.nice : ''
				,confirm: this.$route.query.confirm ? this.$route.query.confirm : ''
				,agencyIdx: this.$route.query.agencyIdx ? this.$route.query.agencyIdx : ''
				,branchIdx: this.$route.query.branchIdx ? this.$route.query.branchIdx : ''
				,distributorIdx: this.$route.query.distributorIdx ? this.$route.query.distributorIdx : ''
				,resellerIdx: this.$route.query.resellerIdx ? this.$route.query.resellerIdx : ''
			}
			,search_option: {
				search_type: [
					{ name: '아이디', column: 1}
					, { name: '가맹점명', column: 2}
					, { name: '사업자번호', column: 3}
					, { name: '대표자명', column: 4}
				]
				,agency: true
			}
			,add_agency_type: this.codes.agency_type
			,items: [
				{ no: 1, idx: 1}
				,{ no: 2, idx: 2}
				,{ no: 3, idx: 3}
				,{ no: 4, idx: 4}
				,{ no: 5, idx: 5}
			]
			,is_excel: false
			,excel_data: {
				name: '가맹점 목록'
				,header: [
					{ key: 0, name: 'PG사', column: 'pgVanName'}
					,{ key: 0, name: '결제방식', column: 'canNonVerifyPayment'}
					,{ key: 0, name: '아이디', column: 'id'}
					,{ key: 0, name: '가맹점명', column: 'shopName'}
                    ,{ key: 0, name: '사업자구분', column: 'businessType'}
                    ,{ key: 0, name: '사업자번호', column: 'businessNumber'}
                    ,{ key: 0, name: '대표자명', column: 'representativeName'}
                    ,{ key: 0, name: '배달비 수수료율', column: 'quickFee'}
                    ,{ key: 0, name: '월세 수수료율', column: 'monthlyFee'}
                    ,{ key: 0, name: 'PG 수수료율', column: 'pgFee'}
					,{ key: 0, name: '정산주기', column: 'settlementCycle'}
					,{ key: 0, name: '나이스인증', column: 'isNice'}
					,{ key: 0, name: '심사', column: 'confirm'}
					,{ key: 0, name: '등록일', column: 'regDate'}
					,{ key: 0, name: '상태', column: 'state'}
					,{ key: 0, name: '1회 한도', column: 'oneLimit'}
					,{ key: 0, name: '월 한도', column: 'monthlyLimit'}
					,{ key: 0, name: '연 한도', column: 'yearlyLimit'}
					,{ key: 0, name: '휴대폰', column: 'hp'}
					,{ key: 0, name: '정산은행', column: 'calculateBankName'}
					,{ key: 0, name: '계좌번호', column: 'calculateAccount'}
					,{ key: 0, name: '예금주', column: 'calculateAccountHolder'}
					,{ key: 0, name: '메일주소', column: 'email'}
					,{ key: 0, name: '상위 영업점 - 총판', column: 'branchName'}
					,{ key: 0, name: '상위 영업점 - 지사', column: 'distributorName'}
					,{ key: 0, name: '상위 영업점 - 대리점', column: 'agencyName'}
					,{ key: 0, name: '상위 영업점 - 딜러', column: 'resellerName'}
				]
				,content: null
			}
		}
	}
	,computed: {
		item_list: function(){
			let self = this
			return this.items.filter(function(item){

				for(let i = 0; i < self.codes.nice_confirm.length; i++){
					if(item.nice == self.codes.nice_confirm[i].code){
						item.nice_name = self.codes.nice_confirm[i].name
						item.nice_color = self.codes.nice_confirm[i].color
						break
					}
				}

				for(let i = 0; i < self.codes.shop_state.length; i++){
					if(item.state == self.codes.shop_state[i].code){
						item.state_name = self.codes.shop_state[i].name
						item.state_color = self.codes.shop_state[i].color
						break
					}
				}

				for(let i = 0; i < self.codes.shop_confirm.length; i++){
					if(item.confirm == self.codes.shop_confirm[i].code){
						item.confirm_name = self.codes.shop_confirm[i].name
						item.confirm_color = self.codes.shop_confirm[i].color
						break
					}
				}

                for(let i = 0; i < self.codes.manual_payment_type.length; i++){
                    if(item.canNonVerifyPayment == self.codes.manual_payment_type[i].code){
                        item.verify_payment_name = self.codes.manual_payment_type[i].name
                        break
                    }
                }

                for(let i = 0; i < self.codes.P02.length; i++){
                    if(item.businessType == self.codes.P02[i].TOTAL_CODE){
                        item.business_name = self.codes.P02[i].code_name
                        break
                    }
                }

				if(!item.shopFee){
					item.shopFee = '-'
				}else{
					item.shopFee += '%'
				}

				return item
			})
		}
	}
	,methods: {
		getData: async function(){
            console.log(this.search, 'search')
			try{
				this.$layout.onLoading()
                if(this.search.search_type) {
                    this.search.keywordType = this.search.search_type
                } else {
                    delete this.search.keywordType
                }
				const result = await this.$Axios({
					method: 'get'
					,url: 'shop/'
					,data: this.search
				})

				if(result.success){
					this.items = result.data.data
					this.search.total_count = result.data.totalCount
                    console.log(this.items, 'shop item')
					localStorage.setItem('query', JSON.stringify(this.search))
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,toDetail: function(item){
			this.$layout.push( { name: 'MerchantDetail', params: { idx: item.idx }, not_query: true})
		}
		,getSearch: function(page){

			if(page){
				this.search.page = page
			}

			//this.$layout.push( { name: this.$route.name, params: this.$route.params, query: this.search })

			this.getData()
		}

		,toExcel: async function(){

			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'shop/excel'
					,data: this.search
				})

				if(result.success){
					this.excel_data.content = result.data
					this.is_excel = true
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}

		}
		,setAgency: function(agency){
			this.search.branchIdx = this.user.branchIdx ? this.user.branchIdx : agency.branchList
			this.search.distributorIdx = this.user.distributorIdx ? this.user.distributorIdx : agency.distributorList
			this.search.agencyIdx = this.user.agencyIdx ? this.user.agencyIdx : agency.agencyList
			this.search.resellerIdx = this.user.resellerIdx ? this.user.resellerIdx : agency.resellerList
			this.getSearch(this.search.page)
		}
		,reset: function(){
			this.search = {
				page: 1
				,size: 10
				,total_count: 0
				,search_type: ''
				,keyword: ''
				,state: ''
				,nice: ''
				,confirm: ''
				,agencyIdx: ''
				,branchIdx: ''
				,distributorIdx: ''
				,resellerIdx: ''
			}

			this.getData()
		}
	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
		this.getData()
	}
}
</script>
<style scoped>
td {
    word-break: break-all;
}
</style>